import React, { useState } from "react";
import { auth, db } from "../../firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import s from "./style.module.css";
import Footer from "components/Footer/Footer";

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false); // État pour afficher la modale
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      // 1. Créer l'utilisateur Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // 2. Ajouter l'utilisateur dans Firestore
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        firstName,
        lastName,
        subscriptionStatus: "none",
        createdAt: new Date().toISOString(),
      });

      // 3. Créer le client Stripe via API Backend
      const response = await fetch(
        "https://api.clubcrm.fr/api/create-customer",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: email,
            firstName: firstName,
            lastName: lastName,
            userId: user.uid,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Échec de la création du client Stripe : ${errorText}`);
      }

      const data = await response.json();
      const stripeCustomerId = data.customerId;

      // 4. Mettre à jour Firestore avec le Stripe ID
      await updateDoc(doc(db, "users", user.uid), {
        stripeCustomerId,
      });

      // 5. Envoyer l'email de vérification
      await sendEmailVerification(user);

      // 6. Afficher la modale après l'envoi de l'email
      setShowModal(true);
    } catch (error) {
      console.error("Erreur lors de l'inscription : ", error);
      if (error.code === "auth/email-already-in-use") {
        setError(
          <>
            L'adresse e-mail est déjà utilisée. Veuillez en utiliser une autre
            ou <Link to="/login">vous connecter</Link>.
          </>
        );
      } else if (error.code === "auth/invalid-email") {
        setError(
          "L'adresse e-mail est invalide. Veuillez entrer une adresse e-mail valide."
        );
      } else if (error.code === "auth/weak-password") {
        setError(
          "Le mot de passe est trop faible. Il doit contenir au moins 6 caractères."
        );
      } else {
        setError(`Une erreur s'est produite : ${error.message}`);
      }
    }
  };

  return (
    <div className={s.container}>
      <Link to="/">Revenir à l'accueil</Link>
      <h1>Inscription</h1>
      <form onSubmit={handleSignup} className={s.form}>
        <input
          className={s.input}
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="Prénom"
          required
        />
        <input
          className={s.input}
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Nom"
          required
        />
        <input
          className={s.input}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          className={s.input}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Mot de passe"
          required
        />
        <button type="submit" className={s.btn}>
          Je m'inscris
        </button>
      </form>
      {error && <p className={s.error}>{error}</p>}

      {/* Modale de confirmation d'email */}
      {showModal && (
        <div className={s.modal}>
          <div className={s.modalContent}>
            <h2>Vérifiez votre e-mail</h2>
            <p>
              Un e-mail de vérification a été envoyé à {email}. Veuillez cliquer
              sur le lien pour confirmer votre adresse.
            </p>
            <button onClick={() => setShowModal(false)} className={s.btn}>
              OK
            </button>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default Signup;
