import React, { useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, useLocation, Link } from "react-router-dom";
import s from "./style.module.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    } catch (error) {
      setError("Erreur lors de la connexion : " + error.message);
    }
  };

  return (
    <div className={s.container}>
      <h1>Connexion</h1>
      <form onSubmit={handleLogin} className={s.form}>
        <input
          className={s.mail}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          className={s.password}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Mot de passe"
          required
        />
        <button type="submit" className={s.btn}>
          Connexion
        </button>
      </form>
      {error && <p className={s.error}>{error}</p>}
      
      {/* Lien vers la page d'inscription */}
      <p className={s.signupLink}>
        Vous n'avez pas encore de compte ?{" "}
        <Link to="/signup" className={s.link}>
          Inscrivez-vous ici
        </Link>
      </p>
    </div>
  );
}

export default Login;
